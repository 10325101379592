import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AppText from '../common/texts/appText';
import IOSNavigationActionSVG from '../../icons/share.svg';
import IOSAddSVG from '../../icons/plus_ios_add.svg';
import { setIOSA2HSShown } from '../../utils/cookies';
import { updatePopups } from '../common/popup';
import createRipple from '../common/ripple';

const StyledA2HSIOS = styled.div`
  background-color: ${({ theme }) => theme.colors.brandPurple};
  bottom: -50vh;
  box-sizing: border-box;
  display: block;
  left: 0;
  padding: 20px;
  position: fixed;
  transition: bottom 0.5s linear;
  width: 100vw;
  z-index: 2000;
`;
const StyledFlexWrapper = styled.div`
  align-items: center;
  display: flex;
`;
const StyledLeftSide = styled.div`
  padding-right: 20px;
`;
const StyledRightSide = styled.div`
  svg {
    margin: 0 7px;
  }
`;
const StyledHeading = styled(AppText)`
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4rem;
  margin: 0 0 10px;
`;
const StyledText = styled(AppText)`
  color: #fff;
  margin: 0;
`;
const StyledClose = styled(AppText)`
  color: #fff;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0;
  padding: 20px;
  position: absolute;
  right: -8px;
  text-transform: uppercase;
  top: -12px;
`;

const StyledRippleWrapper = styled.button`
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
  outline: none;
  padding: 0;
  position: relative;
`;

export const AddToHomeScreenIOS = () => {
	const { t } = useTranslation();

	const onClose = () => {
		updatePopups(false, 'a2hs');
		setIOSA2HSShown();
	};

	return (
		<StyledA2HSIOS id="ios-a2hs-visible-wrapper">
			<StyledClose
				onClick={(e) => {
					onClose();
					createRipple(e, 'light');
				}}
			>
				<StyledRippleWrapper>X</StyledRippleWrapper>
			</StyledClose>
			<StyledFlexWrapper>
				<StyledLeftSide>
					<IOSAddSVG />
				</StyledLeftSide>
				<StyledRightSide>
					<StyledHeading>{t('a2hs.iosPart1')}</StyledHeading>
					<StyledText>
						<span>{t('a2hs.iosPart2')}</span>
						<IOSNavigationActionSVG />
						<span>{t('a2hs.iosPart3')}</span>
					</StyledText>
				</StyledRightSide>
			</StyledFlexWrapper>
		</StyledA2HSIOS>
	);
};

import React, { useEffect } from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import { transparentize } from "polished";
import { useTranslation, withTranslation } from "react-i18next";
import SEO from "../components/seo";
import { LINKS } from "../constants/links";
import WaiterImg from "../images/index/waiter.png";
import AppButton from "../components/common/button";
import { isCameraError, isLoginRedirected, wasIOSA2HSShown } from "../utils/cookies";
import AddToHomeScreenAndroidHome from "../components/A2HS/addToHomeScreenAndroidHome";
import { isUserLogged } from "../utils/localStorage";
import { AddToHomeScreenIOS } from "../components/A2HS/addToHomeScreenIOS";

const StyledHomeWrapper = styled.div`
	background-color: ${({ theme }) => transparentize(0.8, theme.colors.brandBlue)};
	position: relative;
	z-index: 0;
`;

const StyledHomeFlexWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: calc(100vh - ${({ theme }) => theme.constants.headerHeight});
	justify-content: space-around;
	overflow: hidden;
`;

const StyledHalfWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	flex-grow: 60;
	justify-content: center;
	position: relative;
	text-align: center;
	width: 100%;
`;

const StyledHalfWrapperBottom = styled(StyledHalfWrapper)`
	background-image: url(${WaiterImg});
	background-position: center 50px;
	background-repeat: no-repeat;
	background-size: contain;
	flex-grow: 70;
	position: relative;
`;

const StyledHelloText = styled.h1`
	font-size: 1.3rem;
	font-weight: 900;
	line-height: 1.4rem;
	margin: 0 0 25px;
	max-width: 260px;
`;

const StyledInfoText = styled.p`
	font-size: 1.6rem;
	font-weight: 300;
	line-height: 2rem;
	margin: 0;
	max-width: 260px;
`;

const StyledWhiteCircle = styled.div`
	background-color: #fff;
	border-radius: 100%;
	height: 140%;
	left: 50%;
	position: absolute;
	top: -25px;
	transform: translateX(-50%);
	width: 160vw;
	z-index: -1;
`;

const StyledRestpayButton = styled(AppButton)`
	padding: 10px;
`;

const IndexPage = () => {
	const { t } = useTranslation();

	useEffect(() => {
		if (!isUserLogged() && !isLoginRedirected()) {
			navigate(LINKS.LOGIN);
		}
	}, []);

	return (
		<>
			{(isUserLogged() || isLoginRedirected()) && (
				<>
					<SEO title={t("home.title")} />
					<StyledHomeWrapper>
						<StyledHomeFlexWrapper>
							<StyledHalfWrapper>
								<StyledHelloText>{t("home.header")}</StyledHelloText>
								<StyledInfoText>{t("home.description")}</StyledInfoText>
							</StyledHalfWrapper>
							<StyledRestpayButton as={Link} className="big" to={isCameraError() ? LINKS.ENTER_CODE : LINKS.SCAN_QR}>
								{t("home.button")}
							</StyledRestpayButton>
							<StyledHalfWrapperBottom>
								<StyledWhiteCircle />
							</StyledHalfWrapperBottom>
						</StyledHomeFlexWrapper>
					</StyledHomeWrapper>
					{!wasIOSA2HSShown() && <AddToHomeScreenIOS />}
					<AddToHomeScreenAndroidHome />
				</>
			)}
		</>
	);
};

export default withTranslation()(IndexPage);
